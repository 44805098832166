import styled from '@emotion/styled'
import { Button, Props as ButtonProps } from 'app/components/Common/Button'
import React, { memo } from 'react'
import Fade from 'react-reveal/Fade'

export interface Props {
  cta?: ButtonProps[]
  description?: string
  secondCta?: ButtonProps[]
  title?: string
}

export const Info = memo(function Info({
  cta,
  description,
  secondCta,
  title,
}: Props) {
  return (
    <Container>
      {title ? (
        <Fade bottom distance="3.75rem">
          <Title>{title}</Title>
        </Fade>
      ) : null}
      {description ? (
        <Fade bottom distance="3.75rem">
          <Description dangerouslySetInnerHTML={{ __html: description }} />
        </Fade>
      ) : null}
      {cta
        ? cta.map((item, index) => (
            <Fade bottom key={index}>
              <Button {...item} variant="corners" />
            </Fade>
          ))
        : null}
      {secondCta
        ? secondCta.map((item, index) => (
            <Fade bottom key={index}>
              <Button {...item} variant="corners" />
            </Fade>
          ))
        : null}
    </Container>
  )
})

const Container = styled.section`
  max-width: 68.4375rem;
  margin: 9.75rem auto;
  padding: 0 1.5rem;
  text-align: center;

  @media (max-width: 1023px) {
    max-width: none;
    margin: 7.5rem auto;
  }

  @media (max-width: 767px) {
    margin: 6rem auto;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 2.5rem;
  font-weight: 500;
  line-height: 3.375rem;
  margin-bottom: 2.25rem;
`

const Description = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 1.8125rem;
`
